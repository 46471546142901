import React, { useEffect, useState } from 'react'
import nid from 'nid'
import {
  Badge,
  Box,
  Button,
  Flex,
  GridItem,
  Input,
  Radio,
  RadioGroup,
  Select,
  SimpleGrid,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
} from '@chakra-ui/react'
import { formatISO, isWithinInterval } from 'date-fns'
import { useLiveQuery } from 'dexie-react-hooks'
import { WarningIcon } from '@chakra-ui/icons'
import { DayPicker } from 'react-day-picker'
import { useSwipeable } from 'react-swipeable'
import { db, User as UserType, Walk, WalkStatus } from '../store/db'
import { palette, paletteDark } from '../config'
import { addWalk, deleteWalk, updateWalk } from './walks.model'
import {
  formatDateTime,
  formatMultiDateTime,
  isLeader,
  isValidDate,
  log,
} from '../common/utils'
import { syncWithRemote } from '../common/syncWithRemote'
import { sendStandardMessage } from '../Messages/messages.model'
import { WalkersList } from './WalkersList'
import { MakeWakeAdminSection } from './MakeWalkAdminSection'
import { StatusButtons } from './StatusButtons'

import 'react-day-picker/dist/style.css'
import './walks.css'

let disableInput = false

export const MakeWalk = (props: {
  onClick: () => void
  editWalkId: string
  repeat: boolean
}) => {
  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const placeholderColor = useColorModeValue('grey', 'white')
  const [addClass, setAddClass] = useState('')
  const [currentUser, setCurrentUser] = useState<Partial<UserType>>()
  const [draftWalk, setDraftWalk] = useState<Walk>()
  const [amSuperAdmin, setAmSuperAdmin] = useState(false)
  const [showWalkersListFor, setShowWalkersListFor] = useState('')
  const [dateSelected, setDateSelected] = useState<Date>()
  const [dateToSelected, setDateToSelected] = useState<Date>()
  const [showDayPicker, setShowDayPicker] = useState(false)
  const [errors, setErrors] = useState<string[]>([])

  const walkCategories = [
    { id: '', label: 'Day Walk' },
    { id: 'multiday', label: 'Camp or Multi-Day' },
    { id: 'campWalk', label: 'Camp Walk' },
    { id: 'social', label: 'Social or Community' },
    { id: 'other', label: 'Other' },
  ]

  const camps =
    walksDexie?.filter(
      (w) => w.category === 'multiday' && ['open', 'started'].includes(w.status)
    ) || []

  // Handle swipe to close
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      eventData.event.stopPropagation()
      if (eventData.dir === 'Right') props.onClick()
    },
  })
  const swipeHandlersWalkersList = useSwipeable({
    onSwiped: (eventData) => {
      // Do nothing
      eventData.event.stopPropagation()
    },
  })

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('wide')
  }, [])

  // Effect to find the user
  useEffect(() => {
    if (!appStatusDexie?.userId) return
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie.userId)
    if (!user) return // Sometimes usersDexie comes in late
    setCurrentUser(user)
  }, [appStatusDexie?.userId, usersDexie])

  // Effect to set the draft walk ie get the to-edit, latest draft or repeat walk
  useEffect(() => {
    if (!currentUser?.userId) return

    if (draftWalk) return // We already have the draft

    let myDraftWalks = []
    if (props.editWalkId) {
      // Walks to edit
      myDraftWalks =
        walksDexie?.filter((w) => w.walkId === props.editWalkId) || []
    } else {
      // The draft walk being created
      myDraftWalks =
        walksDexie?.filter(
          (w) => w.status === 'draft' && isLeader(currentUser?.userId, w)
        ) || []
    }

    if (props.repeat && myDraftWalks.length) {
      // Repeat this walk - reset relevant fields
      const repeatedDraft = { ...myDraftWalks[0] }
      repeatedDraft.walkId = nid()
      repeatedDraft.leaderId = [currentUser.userId]
      repeatedDraft.date = ''
      repeatedDraft.dateTo = ''
      repeatedDraft.endedAt = ''
      repeatedDraft.startedAt = ''
      repeatedDraft.status = 'draft'
      repeatedDraft.walkers = [currentUser.userId]
      repeatedDraft.walkerChangesByLeader = {}
      setDraftWalk(repeatedDraft)

      // Add the walk locally
      addWalk(repeatedDraft).then(() => {
        updateWalk(repeatedDraft, 'repeated the walk')
      })
      log(`Creating repeat: ${repeatedDraft.title}`, true, {
        userId: currentUser?.userId,
      })
    } else if (myDraftWalks.length) {
      // Edit the existing draft walk
      setDraftWalk(myDraftWalks[0])
      if (isValidDate(myDraftWalks[0].date)) {
        const dateObject = new Date(myDraftWalks[0].date)
        setDateSelected(dateObject)
      }
      if (isValidDate(myDraftWalks[0].dateTo)) {
        const dateObject = new Date(myDraftWalks[0].dateTo)
        setDateToSelected(dateObject)
      }
    }
  }, [walksDexie, currentUser?.userId, props.editWalkId, props.repeat])

  // Effect to get the admin status
  useEffect(() => {
    const user = usersDexie?.find((u) => u?.userId === appStatusDexie?.userId)
    setAmSuperAdmin(Boolean(user?.isSuperAdmin))
  }, [usersDexie, appStatusDexie?.userId])

  // Effect to handle date from date picker
  useEffect(() => {
    if (isValidDate(dateSelected)) {
      handleInput({
        target: { id: 'date', value: formatISO(dateSelected as Date) },
      })
    }
  }, [dateSelected])

  // Effect to handle dateTo from date picker
  useEffect(() => {
    if (isValidDate(dateToSelected)) {
      handleInput({
        target: { id: 'dateTo', value: formatISO(dateToSelected as Date) },
      })
    }
  }, [dateToSelected])

  const handleInput = async (event: any) => {
    if (disableInput) return
    if (!appStatusDexie || !currentUser?.userId) return
    const field = event.target.id
    let value = event.target.value
    if (field === 'fileIds') value = value ? value.split(',') : [] // Need an array here
    if (field === 'leaderId') value = value ? value.split(',') : [] // Need an array here
    if (field === 'isFull') value = value === 'yes'

    let walkToUpdate = draftWalk
    let walkId: string = walkToUpdate?.walkId || ''
    if (!walkId) {
      disableInput = true // Aklow the initial create to finish before updates
      let newWalk: Walk | undefined = undefined
      walkId = nid() // Generate an id
      const now = formatISO(new Date())
      newWalk = {
        walkId,
        createdAt: now,
        updatedAt: now,
        leaderId: [currentUser.userId],
        category: '',
        status: 'draft',
        title: '',
        date: '',
        dateTo: '',
        meetPoint: '',
        meetTime: '',
        startPoint: '',
        startTime: '',
        capacity: '',
        grade: '',
        isFull: false,
        endTime: '',
        startGps: '',
        endGps: '',
        startedAt: '',
        endedAt: '',
        notes: '',
        walkers: [currentUser.userId],
        walkerChangesByLeader: {},
        fileIds: [],
        belongsToId: '',
        chronology: [
          {
            chronologyId: nid(),
            timestamp: now,
            action: 'created the walk',
            byUserName: currentUser.fullName || 'Error/Unknown',
          },
        ],
      }
      await addWalk(newWalk)
      walkToUpdate = newWalk
      log(`Creating new walk by:`, true, { userId: currentUser?.userId })
    }

    // Update the relevant field
    if (!walkToUpdate) return
    const updatedWalk: Walk = {
      ...walkToUpdate,
      walkId,
      [field]: value,
    }

    // If the category is not multi-day, reset any to date
    if (updatedWalk.category !== 'multiday') {
      updatedWalk.dateTo = ''
      setDateToSelected(undefined)
    }

    // Set the draft walk with id asap (before the await)
    setDraftWalk(updatedWalk)
    await updateWalk(updatedWalk)

    disableInput = false

    // Errors
    /* Hmmmmm not working ok
    if (updatedWalk.category === 'campWalk') {
      if (!updatedWalk.belongsToId) {
        const txt = 'This camp walk does not have an associated camp'
        if (!errors.includes(txt)) {
          const newErrors = [...errors, txt]
          setErrors(newErrors)
        }
      } else {
        const camp = walksDexie?.find(
          (w) => w.walkId === updatedWalk.belongsToId
        )
        if (!camp || !camp.date || !camp.dateTo) return
        if (draftWalk?.date) {
          if (
            !isWithinInterval(new Date(draftWalk.date), {
              start: new Date(camp.date),
              end: new Date(camp.dateTo),
            })
          ) {
            const txt = 'The camp walk date is not within camp dates'
            if (!errors.includes(txt)) {
              const newErrors = [...errors, txt]
              setErrors(newErrors)
            }
          }
        }
      }
    }
     */
  }

  const doEditWalk = async (type?: WalkStatus) => {
    switch (type) {
      case 'deleted':
        draftWalk && (await updateWalk(draftWalk, 'deleted the walk'))
        await deleteWalk(draftWalk?.walkId)
        break
      case 'cancelled':
        sendStandardMessage('walkCancelled', draftWalk?.walkId)
      // Fall through to below
      case 'draft':
      case 'expected':
      case 'open':
        const updatedWalk = {
          ...(draftWalk as Walk),
          status: type,
        }
        setDraftWalk(updatedWalk)
        await updateWalk(updatedWalk, `${type} the walk`)
        log(`Walk set to: ${type}`, true, {
          userId: currentUser?.userId,
          walkId: updatedWalk.walkId,
        })
    }

    syncWithRemote()
    props.onClick()
  }

  return (
    <>
      <Box {...swipeHandlers}>
        <Box className={`makeWalkContainer ${addClass}`} bg={heavyBgColor}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text as={'b'}>
              {props.repeat ? 'Repeat' : props.editWalkId ? 'Edit' : 'Make'} a
              walk
            </Text>
          </Box>
          <SimpleGrid columns={4} alignItems="center">
            <GridItem>Leader</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'leader'}
                defaultValue={currentUser?.fullName}
                disabled
              />
            </GridItem>
            <GridItem>Title</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'title'}
                defaultValue={draftWalk?.title || ''}
                placeholder="or a short description"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
                isDisabled={disableInput}
              />
            </GridItem>

            {/* Category */}
            <GridItem>Category</GridItem>
            <GridItem colSpan={3}>
              <Select
                id={'category'}
                value={draftWalk?.category}
                onChange={handleInput}
              >
                {walkCategories.map((c) => {
                  return (
                    <option
                      key={'walkCategory' + c.label}
                      value={c.id}
                      disabled={c.id === draftWalk?.category}
                    >{`${c.label}`}</option>
                  )
                })}
              </Select>
            </GridItem>

            {/* Parent camp to a camp-walk */}
            {draftWalk?.category === 'campWalk' && (
              <>
                <GridItem>Walk at camp</GridItem>
                <GridItem colSpan={3}>
                  <Select
                    id={'belongsToId'}
                    value={draftWalk?.belongsToId}
                    onChange={handleInput}
                  >
                    <option
                      key={'camp-none'}
                      value={''}
                      // disabled={c.walkId === draftWalk?.belongsToId}
                    >{`(No camp)`}</option>
                    {camps.map((c) => {
                      return (
                        <option
                          key={'camp' + c.walkId}
                          value={c.walkId}
                          disabled={c.walkId === draftWalk?.belongsToId}
                        >{`${c.title}`}</option>
                      )
                    })}
                  </Select>
                </GridItem>
              </>
            )}

            {/* Dates */}
            <GridItem>
              {draftWalk?.category === 'multiday' ? 'From - To' : 'Date'}
            </GridItem>
            <GridItem colSpan={3}>
              <Button
                className={
                  draftWalk?.category === 'multiday'
                    ? 'dateButton'
                    : 'dateButton'
                }
                variant={'outline'}
                onClick={() => setShowDayPicker(!showDayPicker)}
              >
                {draftWalk?.category !== 'multiday' &&
                  (isValidDate(draftWalk?.date)
                    ? formatDateTime(draftWalk?.date, 'EEEE d MMM yyyy')
                    : 'Date')}
                {draftWalk?.category === 'multiday' &&
                  formatMultiDateTime(draftWalk?.date, draftWalk?.dateTo)}
              </Button>
            </GridItem>
            {showDayPicker && (
              <GridItem colSpan={4} className={'datePickerContainer'}>
                {draftWalk?.category === 'multiday' ? (
                  <DayPicker
                    mode="range"
                    showOutsideDays
                    weekStartsOn={1}
                    selected={{ from: dateSelected, to: dateToSelected }}
                    onSelect={(dates) => {
                      if (!dates) return
                      if (dates.from) setDateSelected(dates.from)
                      if (dates.to) setDateToSelected(dates.to)
                    }}
                    modifiersClassNames={{ today: 'dateToday' }}
                  />
                ) : (
                  <DayPicker
                    mode="single"
                    showOutsideDays
                    weekStartsOn={1}
                    selected={dateSelected}
                    onSelect={(date) => {
                      if (date) {
                        setDateSelected(date)
                        setShowDayPicker(!showDayPicker)
                      }
                    }}
                    modifiersClassNames={{ today: 'dateToday' }}
                  />
                )}
              </GridItem>
            )}

            <GridItem>Meet at</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'meetPoint'}
                defaultValue={draftWalk?.meetPoint || ''}
                placeholder="for all walkers and carpool"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </GridItem>
            <GridItem>Meet time</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'meetTime'}
                defaultValue={draftWalk?.meetTime || ''}
                placeholder="for all walkers"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </GridItem>
            <GridItem>Start at</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'startPoint'}
                defaultValue={draftWalk?.startPoint || ''}
                placeholder="of the walk"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </GridItem>
            <GridItem>Start time</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'startTime'}
                defaultValue={draftWalk?.startTime || ''}
                placeholder="of the walk"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </GridItem>
            <GridItem>End time</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'endTime'}
                defaultValue={draftWalk?.endTime || ''}
                placeholder="estimated"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </GridItem>
            {/* Attachments */}
            {Boolean(draftWalk?.fileIds?.length) && (
              <>
                <GridItem as={'i'} fontSize={'sm'} colSpan={1}>
                  Attach.
                </GridItem>
                <GridItem colSpan={3}>
                  <Flex alignItems={'center'} h={'40px'}>
                    <Badge mr={2}>{draftWalk?.fileIds?.length}</Badge>
                    {draftWalk?.fileIds?.length === 1 ? 'file' : 'files'}
                  </Flex>
                </GridItem>
              </>
            )}
            {/* Walk grade */}
            <GridItem>Grade</GridItem>
            <GridItem colSpan={3}>
              <Input
                id={'grade'}
                defaultValue={draftWalk?.grade || ''}
                placeholder="of the walk"
                _placeholder={{ opacity: 0.6, color: placeholderColor }}
                onChange={handleInput}
              />
            </GridItem>
            {/* Notes */}
            <GridItem>Notes</GridItem>
            <GridItem colSpan={3}>
              <Textarea
                id={'notes'}
                defaultValue={draftWalk?.notes || ''}
                onChange={handleInput}
              />
            </GridItem>
            {/* is Full */}
            <GridItem>Walk is full</GridItem>
            <GridItem colSpan={3}>
              <RadioGroup
                id={'isFull'}
                onChange={(v) =>
                  handleInput({ target: { id: 'isFull', value: v } })
                }
                value={draftWalk?.isFull ? 'yes' : 'no'}
              >
                <Stack direction="row">
                  <Radio value={'yes'}>Yes</Radio>
                  <Radio value={'no'} ml={8}>
                    No
                  </Radio>
                </Stack>
              </RadioGroup>
            </GridItem>
            {/* Errors */}
            <GridItem colSpan={4}>
              {errors.map((e) => {
                return (
                  <Flex justify={'flex-end'} align={'center'} mt={4}>
                    <WarningIcon mr={2} color={palette.warning} />
                    <Text>{e}</Text>
                  </Flex>
                )
              })}
            </GridItem>

            {/* Admin section */}
            {amSuperAdmin && props.editWalkId && draftWalk?.status && (
              <MakeWakeAdminSection
                onClick={() => {
                  setShowWalkersListFor(draftWalk?.walkId || '')
                }}
                draftWalk={draftWalk}
                onChange={handleInput}
                color={placeholderColor}
              />
            )}
          </SimpleGrid>

          {/* Buttons */}
          <StatusButtons
            editWalkId={props.editWalkId}
            draftWalk={draftWalk}
            doEditWalk={doEditWalk}
          />
        </Box>
      </Box>

      {/* WalkersList panel */}
      {showWalkersListFor && (
        <Box {...swipeHandlersWalkersList}>
          <WalkersList
            walkId={showWalkersListFor}
            onClick={() => setShowWalkersListFor('')}
          />
        </Box>
      )}
    </>
  )
}
