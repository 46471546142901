import {
  Box,
  Button,
  Flex,
  GridItem,
  Radio,
  RadioGroup,
  SimpleGrid,
  Text,
  useToast,
} from '@chakra-ui/react'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useLiveQuery } from 'dexie-react-hooks'
import { AddIcon, CheckIcon, CopyIcon, RepeatClockIcon } from '@chakra-ui/icons'
import { differenceInHours } from 'date-fns'
import { dbBackup } from '../store/dbBackup'
import { formatDateTime, log } from '../common/utils'
import { ButtonConfirm } from '../common/UtilComponents'
import {
  createLocalBackup,
  getRemoteBackups,
  getRemoteSignature,
  getSignature,
} from './LocalBackup.helper'
import {
  BackupObject,
  db,
  Message,
  Plb,
  RemoteBackupObject,
  TableName,
  User,
  Walk,
} from '../store/db'
import { LocalBackup as LocalBackupType } from '../store/dbBackup'
import { replaceWalksTable } from '../Walks/walks.model'
import { replaceOnlyJsonFileOnS3 } from '../common/syncWithRemote'
import { replaceUsersTable } from '../User/user.model'
import { replacePlbsTable } from '../Plbs/plbs.model'
import { replaceMessagesTable } from '../Messages/messages.model'
import { getS3FileContents, getS3Filenames } from '../common/s3Functions'
import { palette } from '../config'

export const LocalBackup = () => {
  const toast = useToast()
  const renderCount = useRef(0)

  const appStatusDexie = useLiveQuery(() => db.appStatus.toCollection().last())
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const usersDexie = useLiveQuery(() => db.users.toArray())
  const plbsDexie = useLiveQuery(() => db.plbs.toArray())
  const messagesDexie = useLiveQuery(() => db.messages.toArray())
  const walksBackupsDexie =
    useLiveQuery(() => dbBackup.walksBackup.toArray()) || []
  const usersBackupsDexie =
    useLiveQuery(() => dbBackup.usersBackup.toArray()) || []
  const plbsBackupsDexie =
    useLiveQuery(() => dbBackup.plbsBackup.toArray()) || []
  const messagesBackupsDexie =
    useLiveQuery(() => dbBackup.messagesBackup.toArray()) || []

  const [remoteSignatures, setRemoteSignatures] = useState<{
    [table: string]: string
  }>({
    walks: '',
    users: '',
    plbs: '',
    messages: '',
  })
  const [remoteBackups, setRemoteBackups] = useState<RemoteBackupObject[]>([])
  const [allowRestoreBackup, setAllowRestoreBackup] = useState(false)
  const [copyCurrent, setCopyCurrent] = useState(false)
  const [selectedBackup, setSelectedBackup] = useState<LocalBackupType>()
  const [selectedTarget, setSelectedTarget] = useState('')
  const [lockedByNames, setLockedByNames] = useState('Looking...')

  const allBackupDexie = walksBackupsDexie
    .sort((a, b) => b.timestamp.localeCompare(a.timestamp))
    .concat(
      usersBackupsDexie.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
    )
    .concat(
      plbsBackupsDexie.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
    )
    .concat(
      messagesBackupsDexie.sort((a, b) =>
        b.timestamp.localeCompare(a.timestamp)
      )
    )

  useEffect(() => {
    getRemoteBackups().then(async (remoteBackupIds) => {
      const remoteBackupRows = await Promise.all(
        remoteBackupIds.map(async (remoteBackupId) => {
          const backup: BackupObject = await getS3FileContents(
            'table-backup',
            remoteBackupId
          )
          const backupBits = remoteBackupId.split('-')
          const backupOf = backupBits[0]
          const backupBy = backupBits[1]
          const row = {
            ...backup,
            backupOf,
            backupBy,
          }
          return row
        })
      )
      remoteBackupRows.sort((a, b) => b.timestamp.localeCompare(a.timestamp))
      setRemoteBackups(remoteBackupRows.slice(0, 50))
    })
  }, [])

  // Avoid too many calls on re-renders - why not useEffect ??
  const getRemoteSignatureMemoized = useCallback(getRemoteSignature, [])

  // Get remote data
  renderCount.current++
  for (const table of ['walks', 'users', 'plbs', 'messages']) {
    if (renderCount.current > 2) continue // Avoid too many calls on re-renders
    getRemoteSignatureMemoized(table as TableName).then((signature) => {
      if (signature !== remoteSignatures[table]) {
        setRemoteSignatures((prev) => ({
          ...prev,
          [table]: signature,
        }))
      }
    })
  }

  // Get lock files
  useEffect(() => {
    getS3Filenames('sync-lock').then((lockIds) => {
      const lockNames = lockIds.map((id) => {
        return usersDexie?.find((u) => u.userId === id)?.fullName || '...'
      })
      setLockedByNames(lockNames.join(', ') || 'Nobody')
    })
  }, [usersDexie])

  const selectBackup = (id: string) => {
    const theLocalBackup = allBackupDexie.find((b) => b.id === id)
    const theRemoteBackup = remoteBackups.find((b) => b.id === id)
    if (theLocalBackup || theRemoteBackup)
      setSelectedBackup(theLocalBackup || theRemoteBackup)
  }

  const selectTarget = (id: string) => {
    setSelectedTarget(id.toLowerCase())
  }

  const canRestore = () => {
    if (!selectedBackup) return false
    if (!selectedTarget) return false
    // Do the backup and target match?
    if (!selectedTarget.includes(selectedBackup.tableName)) return false
    return true
  }

  const doRestoreBackup = async () => {
    log(`Restore: ${selectedTarget}`, true, {
      userId: appStatusDexie?.userId,
    })
    if (selectedTarget.includes('local')) {
      // Overwrite local dexie
      if (selectedTarget.includes('walks')) {
        const newData: Walk[] = selectedBackup?.items as Walk[]
        if (newData) await replaceWalksTable(newData)
      } else if (selectedTarget.includes('users')) {
        const newData: User[] = selectedBackup?.items as User[]
        if (newData) await replaceUsersTable(newData)
      } else if (selectedTarget.includes('plbs')) {
        const newData: Plb[] = selectedBackup?.items as Plb[]
        if (newData) await replacePlbsTable(newData)
      } else if (selectedTarget.includes('messages')) {
        const newData: Message[] = selectedBackup?.items as Message[]
        if (newData) await replaceMessagesTable(newData)
      }
    }
    if (selectedTarget.includes('remote')) {
      // Upload to s3
      if (selectedTarget.includes('walks')) {
        const newData: Walk[] = selectedBackup?.items as Walk[]
        await replaceOnlyJsonFileOnS3('walks', newData)
      } else if (selectedTarget.includes('users')) {
        const newData: User[] = selectedBackup?.items as User[]
        await replaceOnlyJsonFileOnS3('users', newData)
      } else if (selectedTarget.includes('plbs')) {
        const newData: Plb[] = selectedBackup?.items as Plb[]
        await replaceOnlyJsonFileOnS3('plbs', newData)
      } else if (selectedTarget.includes('messages')) {
        const newData: Message[] = selectedBackup?.items as Message[]
        await replaceOnlyJsonFileOnS3('messages', newData)
      }
    }

    toast({
      title: `It's a good idea to sync. And check the data`,
      isClosable: true,
    })
    setAllowRestoreBackup(false)
  }

  const timeDiffHuman = (timestamp: string): string => {
    const diffHours = differenceInHours(new Date(), new Date(timestamp))
    if (diffHours <= 72) return `${diffHours} hours ago`
    return `${(diffHours / 24).toFixed(1)} days`
  }

  return (
    <>
      <RadioGroup onChange={selectBackup}>
        {/* The local backups we have */}
        <Box className={'listItemContainer'} p={0}>
          <Box mt={8} className={'backupsList'}>
            <Text as={'b'}>Local backups (by type):</Text>
            <SimpleGrid columns={5} alignItems="center">
              {allBackupDexie?.map((b) => (
                <Fragment key={b.id}>
                  <GridItem colSpan={2}>
                    <Radio mr={2} value={b.id} /> {b.signature}
                  </GridItem>{' '}
                  <GridItem colSpan={3}>
                    {formatDateTime(b.timestamp, 'dd-MM HH:mm:ss')}
                    {' = '}
                    {timeDiffHuman(b.timestamp)}
                  </GridItem>
                </Fragment>
              ))}
            </SimpleGrid>
            {/*</RadioGroup>*/}
          </Box>
        </Box>

        {/* The remote backups we found */}
        <Box className={'listItemContainer'} p={0}>
          <Box mt={8} className={'backupsList'}>
            <Text as={'b'}>Remote backups (by date):</Text>
            <SimpleGrid columns={6} alignItems="center">
              {remoteBackups.map((b) => (
                <Fragment key={b.id}>
                  <GridItem colSpan={2}>
                    <Radio mr={2} value={b.id} /> {b.signature}
                  </GridItem>
                  <GridItem colSpan={3}>
                    {formatDateTime(b.timestamp, 'dd-MM HH:mm:ss')}
                    {' = '}
                    {timeDiffHuman(b.timestamp)}
                  </GridItem>
                  <GridItem colSpan={1}>{b.backupBy}</GridItem>
                </Fragment>
              ))}
            </SimpleGrid>
          </Box>
        </Box>
      </RadioGroup>

      {/* Current local data */}
      <Box className={'listItemContainer'} mt={4} p={0}>
        <Box mt={8} className={'backupsList'}>
          <Text as={'b'}>Current data:</Text>
          <RadioGroup onChange={selectTarget}>
            <SimpleGrid columns={5} alignItems="center">
              <GridItem colSpan={2}>Local</GridItem>
              <GridItem colSpan={3}>Shared (=Remote)</GridItem>
              <GridItem colSpan={2}>
                <Radio mr={2} value={'toLocalWalks'} />
                {getSignature('walks', walksDexie)}
              </GridItem>
              <GridItem colSpan={3}>
                <Radio mr={2} value={'toRemoteWalks'} />
                {remoteSignatures.walks}
              </GridItem>
              <GridItem colSpan={2}>
                <Radio mr={2} value={'toLocalUsers'} />
                {getSignature('users', usersDexie)}
              </GridItem>
              <GridItem colSpan={3}>
                <Radio mr={2} value={'toRemoteUsers'} />
                {remoteSignatures.users}
              </GridItem>
              <GridItem colSpan={2}>
                <Radio mr={2} value={'toLocalPlbs'} />
                {getSignature('plbs', plbsDexie)}
              </GridItem>
              <GridItem colSpan={3}>
                <Radio mr={2} value={'toRemotePlbs'} />
                {remoteSignatures.plbs}
              </GridItem>
              <GridItem colSpan={2}>
                <Radio mr={2} value={'toLocalMessages'} />
                {getSignature('messages', messagesDexie)}
              </GridItem>
              <GridItem colSpan={3}>
                <Radio mr={2} value={'toRemoteMessages'} />
                {remoteSignatures.messages}
              </GridItem>
              {/* Buttons */}
              <GridItem colSpan={5} mt={4}>
                <Flex justify={'space-between'}>
                  <ButtonConfirm
                    text={`now`}
                    btnText={'Make backup'}
                    okAction={async () => {
                      const result = await createLocalBackup('all')
                      toast({
                        title:
                          result === 'same'
                            ? `All data the same as last backup, so no new backup made`
                            : 'Backup made for ' + result,
                        isClosable: true,
                      })
                    }}
                    icon={<AddIcon />}
                    color={palette.info}
                  />
                  <Button
                    leftIcon={<RepeatClockIcon />}
                    size={'sm'}
                    variant={'solid'}
                    colorScheme={palette.info}
                    onClick={() => {
                      setAllowRestoreBackup(!allowRestoreBackup)
                      setCopyCurrent(false)
                    }}
                  >
                    Restore from backup
                  </Button>

                  <Button
                    leftIcon={<CopyIcon />}
                    size={'sm'}
                    variant={'solid'}
                    colorScheme={palette.info}
                    onClick={() => {
                      setAllowRestoreBackup(false)
                      setCopyCurrent(!copyCurrent)
                    }}
                  >
                    Copy current
                  </Button>
                </Flex>
              </GridItem>
            </SimpleGrid>
          </RadioGroup>

          {allowRestoreBackup && (
            <Box mt={4}>
              <Text>
                Restore a single backup to current local or shared data. Steps:
              </Text>
              <Text>1 - Close the app</Text>
              <Text>2 - Select a backup</Text>
              <Text>
                3 - Select of the same type the current Local or Shared data to
                overwrite
              </Text>
              <Text>4 - Click 'Go'</Text>
              <Text>5 - Open the app</Text>
              <ButtonConfirm
                text={`overwrite the current data with the backup`}
                btnText={'Go'}
                okAction={doRestoreBackup}
                icon={<CheckIcon />}
                color={palette.info}
                isDisabled={!canRestore()}
              />
            </Box>
          )}

          {copyCurrent && (
            <Box mt={4}>
              <Text>
                Copy the current Local data and overwrite the Shared data.
                Steps:
              </Text>
              <Text>1 - Make a backup</Text>
              <Text>2 - Restore that backup to Shared data</Text>
            </Box>
          )}
        </Box>
      </Box>

      {/* Lock files */}
      <Box className={'listItemContainer'} mt={4} p={0}>
        <Box mt={8} className={'backupsList'}>
          <Text as={'b'}>Lock files by:</Text>
          <Text>{lockedByNames}</Text>
        </Box>
      </Box>
    </>
  )
}
