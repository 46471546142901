import React from 'react'
import { Walk } from '../store/db'
import { GridItem, SimpleGrid, Text } from '@chakra-ui/react'

type Props = {
  walk?: Walk
}

export const Chronology = (props: Props) => {
  if (!props.walk) return null
  if (!props.walk.chronology?.length) return <>Chronology not available</>

  const allItems = props.walk.chronology || []
  allItems.sort((a, b) => {
    return a.timestamp.localeCompare(b.timestamp)
  })

  const list = allItems.map((item) => {
    const timestamp = item.timestamp
      .slice(0, 16)
      .replaceAll('-', '')
      .replace('T', ' ')
    return [timestamp, `${item.byUserName} ${item.action}`]
  })

  return (
    <>
      <Text as={'i'} fontSize={'xs'}>
        Note: until mid 2025 the chronology can be incomplete
      </Text>
      {list.map((item, index) => (
        <SimpleGrid key={'chron' + index} columns={5}>
          <GridItem colSpan={2}>{item[0]}</GridItem>
          <GridItem colSpan={3}>{item[1]}</GridItem>
        </SimpleGrid>
      ))}
    </>
  )
}
