import { differenceInSeconds, formatISO, differenceInHours } from 'date-fns'
import { cloneDeep, isArray, isEqual, uniqBy, uniqWith } from 'lodash'
import nid from 'nid'
import {
  AppStatus,
  db,
  Message,
  Plb,
  SyncActions,
  SyncOptions,
  SyncStatus,
  TableName,
  User,
  Walk,
  WalkerChangeType,
} from '../store/db'
import { collapseText, isLeader, isOnline, log } from './utils'
import {
  createS3JsonFile,
  deleteS3File,
  getS3FileContents,
  getS3Filenames,
} from './s3Functions'
import { constants, isSyncing } from '../config'
import { replaceUsersTable, userIsBlocked } from '../User/user.model'
import { replaceWalksTable } from '../Walks/walks.model'
import { replacePlbsTable } from '../Plbs/plbs.model'
import { deleteMessage, replaceMessagesTable } from '../Messages/messages.model'
import differenceInDays from 'date-fns/differenceInDays'
import { dbBackup } from '../store/dbBackup'
import {
  findChangesStatus,
  getLengthDiff,
  getLocalData,
} from './syncWithRemote.helpers'
import { createLocalBackup } from '../LocalBackup/LocalBackup.helper'

export const MESSAGE_SEPARATOR = '---'
export const ERROR_GETTING_CONTENT = 'ERROR_GETTING_CONTENT'
export const FILE_READ_ERROR = 'FILE_READ_ERROR'
export const ERROR = 'ERROR'
export const WARNING = 'WARNING'

export type CallResult = {
  status: 'success' | 'error'
  isSuccess: boolean
  message: string
}

// let syncStartedAt = 0
let retryInSecId: any

//============= Dexie functions ===============
export const updateSingleSyncProperty = async (
  properties: { key: string; value: string | boolean | SyncOptions }[]
): Promise<void> => {
  const currentAppStatus = await db.appStatus.get('Tripsheets')
  const newSyncStatus = { ...currentAppStatus?.sync }
  for (let i = 0; i < properties.length; i++) {
    // @ts-ignore - not allowed [key: string]
    newSyncStatus[properties[i].key] = properties[i].value
  }
  await db.appStatus.update('Tripsheets', { sync: newSyncStatus })
}

//============= The actual sync ===============
export const syncWithRemote = async (): Promise<SyncStatus> => {
  // Do we have internet?
  const currentAppStatusBefore = await db.appStatus.get('Tripsheets')
  if (!currentAppStatusBefore?.haveInternet) return ''

  // Sort out the parameters
  const action: SyncActions = currentAppStatusBefore.sync.options?.action || ''
  const sanityRegisterFor =
    currentAppStatusBefore.sync.options?.sanityCheck?.registerFor || ''

  // Am I already syncing? Is there a non-blocked user?
  if (isSyncing.isSyncing) return ''
  if (currentAppStatusBefore.sync.status === 'syncing') return ''
  if (!currentAppStatusBefore.userId && !action) {
    await updateSingleSyncProperty([
      { key: 'message', value: 'Must be someone to allow sync' },
    ])
    return ''
  }
  if (await userIsBlocked(currentAppStatusBefore.userId)) return ''

  // Only allow a sync max every 10 sec. Re-renders cause too many syncs that create duplicate remote file
  const timeDiffSec = differenceInSeconds(
    new Date(),
    new Date(currentAppStatusBefore.sync.lastSyncAt)
  )
  if (timeDiffSec < 10) {
    log(`Can't sync within ${timeDiffSec} seconds`)
    const retryInSec = Math.max(1, 10 - timeDiffSec)
    await updateSingleSyncProperty([
      { key: 'status', value: 'needed' },
      {
        key: 'message',
        value: `Retry in ${retryInSec} seconds`,
      },
    ])
    if (retryInSecId) clearTimeout(retryInSecId)
    retryInSecId = setTimeout(() => syncWithRemote(), retryInSec * 1000)
    return ''
  }
  isSyncing.isSyncing = true

  if (action === 'pull-only') {
    // Just get the users, walks and plb tables from remote
    log(`Getting users & walks & plbs for a new user...`)
    await pullFromRemote('users')
    await pullFromRemote('walks')
    await pullFromRemote('plbs')
    await pullFromRemote('messages')
    await updateSingleSyncProperty([
      { key: 'status', value: 'done' },
      { key: 'lastSyncAt', value: formatISO(new Date()) },
      { key: 'message', value: '' },
      { key: 'options', value: { action: '' } },
    ])
    isSyncing.isSyncing = false
    return ''
  }

  // Set up the sync lock for other users
  const lockedBy = await syncLock()
  if (lockedBy) {
    const lockedByUser = await db.users.get(lockedBy)
    const name =
      lockedByUser?.userId === currentAppStatusBefore.userId
        ? 'me'
        : lockedByUser?.fullName || lockedBy
    log(`Sync locked by ${name}`)
    await updateSingleSyncProperty([
      {
        key: 'message',
        value:
          name === 'me'
            ? `Please wait 10 sec and try again`
            : `Lock by ${name}: please retry again in 5-10 minutes`,
      },
      { key: 'status', value: 'error' },
    ])
    isSyncing.isSyncing = false
    return 'error'
  }

  // log(`Sync is starting`) // after ${timeDiffSec > 99 ? '>99' : timeDiffSec} sec`)
  try {
    await updateSingleSyncProperty([
      { key: 'status', value: 'syncing' },
      { key: 'lastSyncAt', value: formatISO(new Date()) },
      { key: 'message', value: '' },
    ])

    // Do the sync for each table
    for (const table of ['users', 'walks', 'plbs', 'messages'] as TableName[]) {
      // A last attempt to check if we're the only ones syncing
      const allSyncIds = await checkForMultipleSyncs(table)
      if (allSyncIds) {
        const analyticsMessage = `WSYNRETRY ${allSyncIds.length}`
        log(analyticsMessage, true, { userId: currentAppStatusBefore.userId })
        const msg = [WARNING]
        msg.push(
          `It looks like ${allSyncIds.length} people are syncing at once, so stopping now`
        )
        msg.push(`Please retry by tapping the Sync button at the top`)
        await updateSingleSyncProperty([
          { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
          { key: 'status', value: 'bigError' },
          { key: 'options', value: { action: '' } },
        ])

        // Leave the loop
        break
      }

      // Are there changes for this table
      const { localChanges, remoteChanges, error } = await findChangesStatus(
        table,
        currentAppStatusBefore
      )
      // log(
      //   `Sync changes for ${table}: local: ${localChanges}, remote: ${remoteChanges}, error: ${error}`
      // )
      if (error) {
        // Something went wrong
        const currentAppStatus = await db.appStatus.get('Tripsheets')
        const errorMessage = currentAppStatus?.sync.message
        log('Sync finished with error: ' + errorMessage, true, {
          userId: currentAppStatus?.userId,
        })
        if (errorMessage?.slice(0, 7) === 'MISSING') {
          const table = errorMessage.split('-')[1] as TableName
          log(`ESYNMIS ${table.slice(0, 1)}`, true, {
            userId: currentAppStatus?.userId,
          })

          // Apparently a file is missing. just wanna see what the actual status is remote
          // This is just to see if all files are really gone for this table, or, is there a dup sync at same time and maybe some files have now come back
          const remoteIds = await getS3Filenames(table)
          log(`ESYNMIS2 ${table.slice(0, 1)}:${remoteIds.join(',')}`, true, {
            userId: currentAppStatus?.userId,
          })

          // And how many people are syncing at once
          const remoteSyncIds = await getS3Filenames('sync-lock')
          log(
            `ESYNMIS3 ${table.slice(0, 1)} ${remoteSyncIds.length}p syncing`,
            true,
            {
              userId: currentAppStatus?.userId,
            }
          )

          // And check what walk backup files we have. It's usually the walks. Ie can i use them to restore?
          const walkBackups = await dbBackup.walksBackup.toArray()
          for (const w of walkBackups) {
            const diffHours = differenceInHours(
              new Date(),
              new Date(w.timestamp)
            )
            log(`ESYNMIS4 ${w.signature} -${diffHours}h`, true, {
              userId: currentAppStatus?.userId,
            })
          }

          /* Used to do this
          log(`Data was missing - will push my data for ${table}`)
          await updateSingleSyncProperty([
            { key: 'message', value: `Will push my data for ${table}` },
          ])
          await pushToRemote(table, true)
           */
          const msg = [ERROR]
          msg.push(`The shared data (remote) is missing. so can't sync`)
          msg.push(`Tell Mark: missing ${table}`)
          await updateSingleSyncProperty([
            { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
            { key: 'status', value: 'bigError' },
          ])
          return 'error'
        } else {
          await updateSingleSyncProperty([{ key: 'status', value: 'error' }])
          log('Sync finished with error: ' + errorMessage)
          await syncLock('remove')
          isSyncing.isSyncing = false
          return 'error'
        }
      } else if (remoteChanges && localChanges) {
        // Changes everywhere
        // log(`Merging my ${table} changes with remote...`)
        await mergeChanges(table)
      } else if (remoteChanges && !localChanges) {
        // Only remote changes, so pull from there
        // log(`No local changes, only remote for ${table}. Pulling...`)
        await pullFromRemote(table)
        // await mergeChanges(table)
      } else if (!remoteChanges && localChanges) {
        // Only local changes
        // log(`No remote changes, only local for ${table}. Pushing...`)
        await pushToRemote(
          table,
          !Boolean(sanityRegisterFor),
          sanityRegisterFor
        )
      } else {
        // No changes at all, so do nothing
      }
    }

    // Remove the sync lock
    await syncLock('remove')

    await updateSingleSyncProperty([
      { key: 'btnText', value: 'Sync' },
      { key: 'status', value: 'done' },
    ])
    isSyncing.isSyncing = false

    // Now make a backup of the synced result
    await createLocalBackup('all')

    return 'done'
  } catch (error) {
    log('Sync finished with error: ' + error)
    await syncLock('remove')
    await updateSingleSyncProperty([
      { key: 'status', value: 'error' },
      { key: 'message', value: (error as Error).message.slice(0, 40) },
    ])
    isSyncing.isSyncing = false
    return 'error'
  }
}

//============= Helpers ===============
const pushToRemote = async (
  table: TableName,
  noSanityBefore = false,
  sanityRegisterForWalkId = ''
) => {
  await updateSingleSyncProperty([
    { key: 'message', value: `Sharing updates for "${table}"...` },
  ])

  // Does this look ok?
  if (!noSanityBefore) {
    const hasError = await sanityCheckSyncResult(
      'beforePush',
      table,
      sanityRegisterForWalkId
    )
    if (hasError) return
  }

  // Create local exports for this table
  const allRecords = await db[table].toArray()
  log(`Sharing updates for "${table}"`)

  // Create the S3 filem with local flag (browser only) to detect not-finished
  await updateSingleSyncProperty([{ key: 'pushingTable', value: table }])
  // console.time('s3WriteAll')
  await createAndCheckJsonFileOnS3(table, allRecords, sanityRegisterForWalkId)
  // console.timeEnd('s3WriteAll')
  await updateSingleSyncProperty([{ key: 'pushingTable', value: '' }])
}

const createAndCheckJsonFileOnS3 = async (
  table: 'users' | 'walks' | 'plbs' | 'messages',
  allRecords: Walk[] | User[] | Plb[] | Message[],
  sanityRegisterForWalkId: string
) => {
  // Create remote file
  const newTableRemoteId = nid()
  const s3UploadStatus = await createS3JsonFile(
    table,
    newTableRemoteId,
    allRecords
  )

  const currentAppStatus = await db.appStatus.get('Tripsheets')
  if (s3UploadStatus.isSuccess) {
    // Delete the old remote file
    const tableIdKey = `${table}RemoteId`
    // @ts-ignore - [key: string] not allowed
    const oldId = currentAppStatus?.sync[tableIdKey] || ''
    if (oldId) deleteS3File(table, oldId)

    // Update the remote id and local update status
    await updateSingleSyncProperty([
      { key: table + 'RemoteId', value: newTableRemoteId },
      { key: table + 'LocalUpdates', value: false },
      { key: 'message', value: '' },
    ])

    // Check if all ok after push
    await sanityCheckSyncResult('afterPush', table, sanityRegisterForWalkId)
  } else {
    log(`EPUSH ${table.slice(0, 1)} ${s3UploadStatus.message}`, true, {
      userId: currentAppStatus?.userId,
    })
    await updateSingleSyncProperty([
      { key: 'status', value: 'error' },
      { key: 'message', value: s3UploadStatus.message },
      { key: 'options', value: { action: '' } },
    ])
  }
}

// Only replace the file, no sanity checks nor update of local status
export const replaceOnlyJsonFileOnS3 = async (
  table: 'users' | 'walks' | 'plbs' | 'messages',
  allRecords: Walk[] | User[] | Plb[] | Message[]
) => {
  // Create remote file
  const newTableRemoteId = nid()
  const s3UploadStatus = await createS3JsonFile(
    table,
    newTableRemoteId,
    allRecords
  )

  if (s3UploadStatus.isSuccess) {
    // Delete the old one
    const currentAppStatus = await db.appStatus.get('Tripsheets')
    const tableIdKey = `${table}RemoteId`
    // @ts-ignore - [key: string] not allowed
    const oldId = currentAppStatus?.sync[tableIdKey] || ''
    if (oldId) deleteS3File(table, oldId)

    // All good
    return
  }

  const msg = [ERROR]
  msg.push(`Restoring ${table} to shared/remote data has failed`)
  msg.push(`Tell Support: ${table} ${s3UploadStatus.message}`)
  await updateSingleSyncProperty([
    { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
    { key: 'status', value: 'bigError' },
  ])
}

const pullFromRemote = async (table: TableName) => {
  // Pull the remote file
  const [remoteId] = await getS3Filenames(table)
  if (!remoteId) {
    const msg = `Data file for ${table} is missing during pull`
    const currentAppStatus = await db.appStatus.get('Tripsheets')
    log(msg, true, { userId: currentAppStatus?.userId })
    await updateSingleSyncProperty([
      { key: 'status', value: 'error' },
      { key: 'message', value: msg },
    ])
    return
  }
  const fileContents = await getS3FileContents(table, remoteId)
  log(`Pulling from remote ${table}: ${fileContents.length}`)

  // Overwrite local dexie
  if (fileContents && isArray(fileContents) && fileContents.length > 0) {
    updateSingleSyncProperty([
      { key: 'message', value: `Pulling the remote changes for "${table}"...` },
    ])

    try {
      if (table === 'users') {
        await replaceUsersTable(fileContents as User[])
      } else if (table === 'walks') {
        await replaceWalksTable(fileContents as Walk[])
      } else if (table === 'plbs') {
        await replacePlbsTable(fileContents as Plb[])
      } else if (table === 'messages') {
        await replaceMessagesTable(fileContents as Message[])
      }

      // Update the remote id and local update status
      await updateSingleSyncProperty([
        { key: table + 'RemoteId', value: remoteId },
        { key: table + 'LocalUpdates', value: false },
        { key: 'message', value: '' },
      ])
    } catch (error) {
      log(`Error (general catch) while updating ${table}: ${error}`, true)
      await updateSingleSyncProperty([
        { key: 'status', value: 'error' },
        { key: 'message', value: `Error while updating ${table}: ${error}` },
      ])
    }
  }
}

const mergeChanges = async (table: TableName) => {
  console.log('xyzzy starting merge...')
  const currentAppStatus = await db.appStatus.get('Tripsheets')
  if (!currentAppStatus) return

  const myUser =
    (currentAppStatus.userId &&
      (await db.users.get(currentAppStatus.userId))) ||
    ({} as User)
  const myUserId = myUser.userId || ''

  // Get the local contents
  const localContents: User[] | Walk[] | Plb[] | Message[] =
    table === 'users'
      ? ((await db.users.toArray()) as User[])
      : table === 'walks'
      ? ((await db.walks.toArray()) as Walk[])
      : table === 'plbs'
      ? ((await db.plbs.toArray()) as Plb[])
      : table === 'messages'
      ? ((await db.messages.toArray()) as Message[])
      : []

  // Get the remote contents
  const [remoteId] = await getS3Filenames(table)
  if (!remoteId) {
    // If the file is missing, replace it with the local data
    const msg = `Remote data file for ${table} is missing - using local file`
    log(msg)
    await updateSingleSyncProperty([
      { key: 'status', value: 'error' },
      { key: 'message', value: msg },
    ])
  }
  const remoteContents = remoteId
    ? await getS3FileContents(table, remoteId)
    : localContents

  // Start the merge
  await updateSingleSyncProperty([
    { key: 'message', value: `Merging changes for "${table}"...` },
  ])
  if (table === 'users') {
    // Merge rules for users
    //    1. all remote users except myself, plus my own data
    //    except: remote users with status 'deleted' that are gone locally, remove them (=hard delete)
    //    2. for me, for admin, canSee and blocked status: if local status is recent, use that, otherwise accept the remote status
    //    3. if empty dexie, just all users
    if (myUserId) {
      const remoteOtherUsers = (remoteContents as User[]).filter(
        (u) => u.userId !== myUserId
      )
      const remoteMyUser = (remoteContents as User[]).find(
        (u) => u.userId === myUserId
      )
      if (!remoteMyUser) return

      // Filter out hard deleted users
      const remoteUsersWithoutHardDeleted = remoteOtherUsers.filter((u) => {
        if (u.status !== 'deleted') return true
        const localIndex = (localContents as User[]).findIndex(
          (c) => c.userId === u.userId
        )
        return localIndex !== -1
      })

      /*
      Let's relax this (ie only allow statusses to be set from remote) a bit. Let's just accept the most recent
      update for all data, except the blocked status (that is always the remote value)

      // Set my statusses for canSee, admin and blocked
      myUser.isBlocked = remoteMyUser?.isBlocked || false
      const recentThresholdMin = 10
      if (
        differenceInMinutes(new Date(), new Date(myUser.updatedAt || 1)) >=
        recentThresholdMin
      ) {
        // Use the remote status, because local changes were made a long time (>10 min) ago
        myUser.canSee = remoteMyUser?.canSee || false
        myUser.isAdmin = remoteMyUser?.isAdmin || false
      }
       */
      // For myself, use the most recent  data, except the blocked status
      const iAmBlocked = remoteMyUser?.isBlocked || false
      const correctMyUserData =
        myUser.updatedAt >= remoteMyUser?.updatedAt ? myUser : remoteMyUser
      if (!correctMyUserData) return
      correctMyUserData.isBlocked = iAmBlocked

      // Create new data set for users
      const mergedUsers = remoteUsersWithoutHardDeleted
        .concat([correctMyUserData])
        .sort((a, b) => a.fullName.localeCompare(b.fullName))

      await replaceUsersTable(mergedUsers)
    } else {
      await replaceUsersTable(remoteContents as User[])
    }
  } else if (table == 'walks') {
    // Merge rules for walks:
    //    1 use the remote walks and hard-delete: ie remove the walks with status 'deleted' that are gone locally
    //    2 for duplicated (ie local and remote) my-walks as leader: use the most recent version, except:
    //          for the walkers, that is step 3
    //          for the chronology, that is step 4
    //    3 for all the walks: use my walk status from combined list (based on remote) and leader changes
    //    4 for all the walks: combine the local and remote chronologies
    // Note:
    //   the merge favours the local walk status for me (step 3).
    //   Ie: if remote and local both have changes, the walk status for me will be the local status.
    //   So, I register on device1 and update a walk on device2, the registration on device1 will not merge with device2.

    // Step 1: use the remote walks
    const localWalks = await db.walks.toArray()
    const remoteWalks = remoteContents as Walk[]

    // Filter out hard deleted walks
    const remoteWalksWithoutHardDeleted = remoteWalks.filter((w) => {
      if (w.status !== 'deleted') return true
      const localIndex = localWalks.findIndex((lw) => lw.walkId === w.walkId)
      return localIndex !== -1
    })
    const allWalks = cloneDeep(remoteWalksWithoutHardDeleted)

    // Step 2: for duplicated my-walks as leader: use the most recent version, except the walkers.
    // Most recent so that the leader can update on phone (eg start/stop) and computer.
    // Except the walkers 'cos walkers themselves update their status (step 3).
    // Except the chronology, 'cos that is just combined (step 4).
    localWalks.map((lw) => {
      // We only want local my-walks (so: i am the leader)
      if (!isLeader(myUserId, lw)) return

      // Find the most recent of the walks
      const sameWalkIndex = remoteWalksWithoutHardDeleted.findIndex(
        (rw) => rw.walkId === lw.walkId
      )
      if (sameWalkIndex < 0) {
        // This shouldnt happen - is this the cause deleted walks come back ????? TODO
        allWalks.push(lw)
      } else {
        if (allWalks[sameWalkIndex].updatedAt < lw.updatedAt) {
          // Use the local version iso remote version, but keeping the: remote walkers, leaderChanges & chronology
          const combined = {
            ...lw,
            walkers: allWalks[sameWalkIndex].walkers,
            walkerChangesByLeader:
              allWalks[sameWalkIndex].walkerChangesByLeader,
            chronology: allWalks[sameWalkIndex].chronology,
          }
          allWalks.splice(sameWalkIndex, 1, combined)
        }
      }
    })

    // Step 3: Now merge the walkers arrays.
    // Ie ensure the walkers arrays are correct looking at changes by the walker and by the leader
    const mergedWalksWithWalkers = allWalks.map((aw) => {
      const currentWalkers = aw.walkers || []
      const localWalk = localWalks.find((w) => w.walkId === aw.walkId)

      // Am I walking? Was I added or removed by leader?
      const iAmWalkingLocal = localWalk?.walkers.includes(myUserId) || false
      let iAmWalking = iAmWalkingLocal // Default is the local status

      if (aw.walkerChangesByLeader) {
        const changeType = aw.walkerChangesByLeader[myUserId]
        if (changeType === WalkerChangeType.Added) {
          iAmWalking = true // Added by leader
        } else if (changeType === WalkerChangeType.Removed) {
          iAmWalking = false // Removed by leader
        }
      }

      // Now remove myself from the changesByLeader
      if (!aw.walkerChangesByLeader) aw.walkerChangesByLeader = {}
      if (aw.walkerChangesByLeader[myUserId] !== undefined)
        delete aw.walkerChangesByLeader[myUserId]

      let mergedWalkers = []
      if (iAmWalking) {
        mergedWalkers = currentWalkers.concat([myUserId])
        mergedWalkers = [...new Set(mergedWalkers)]
      } else {
        mergedWalkers = currentWalkers.filter((cw) => cw !== myUserId)
      }

      return {
        ...aw,
        walkers: mergedWalkers,
      }
    })

    // Step 4: Combine the chronologies
    const mergedWalksWithChronologies = mergedWalksWithWalkers.map((aw) => {
      const currentChronology = aw.chronology || []
      const localWalk = localWalks.find((w) => w.walkId === aw.walkId)

      const combinedChronology = currentChronology.concat(
        localWalk?.chronology || []
      )

      const uniqueChronology = uniqBy(combinedChronology, 'chronologyId')
      return {
        ...aw,
        chronology: uniqueChronology,
      }
    })

    await replaceWalksTable(mergedWalksWithChronologies)
  } else if (table === 'plbs') {
    // Merge rules for Plbs
    //   1. Combine local and remote ids
    //   2. For duplicates, use the latest version
    //   note: this means that plbs are never hard deleted

    // Step 1: create a super set of ids
    const localIds = (localContents as Plb[]).map((l) => l.plbId)
    const remoteIds = (remoteContents as Plb[]).map((r) => r.plbId)
    const allItemsIdsWithDuplicates = localIds.concat(remoteIds)
    const allDataIds = uniqWith(allItemsIdsWithDuplicates, (a, b) => a === b)

    // Step 2: get the latest of each
    const allPlbs: Plb[] = []
    for (const id of allDataIds) {
      const remoteData = (remoteContents as Plb[]).find((r) => r.plbId === id)
      const localData = (localContents as Plb[]).find((l) => l.plbId === id)
      if (!remoteData && !localData) continue

      const remoteUpdated = remoteData?.updatedAt || ''
      const localUpdated = localData?.updatedAt || ''
      const latest = remoteUpdated >= localUpdated ? 'remote' : 'local'

      // @ts-ignore - one or other is always defined
      allPlbs.push(latest === 'remote' ? remoteData : localData)
    }
    await replacePlbsTable(allPlbs)
  } else if (table === 'messages') {
    // Merge rules for Messages
    //   1. Combine local and remote ids
    //   2. For duplicates, use the latest version
    //   3. Remove expired messages

    // Step 1: create a super set of ids
    const localIds = (localContents as Message[]).map((l) => l.messageId)
    const remoteIds = (remoteContents as Message[]).map((r) => r.messageId)
    const allItemsIdsWithDuplicates = localIds.concat(remoteIds)
    const allDataIds = uniqWith(allItemsIdsWithDuplicates, (a, b) => a === b)

    // Step 2: get the latest of each
    const allMessages: Message[] = []
    for (const id of allDataIds) {
      const remoteData = (remoteContents as Message[]).find(
        (r) => r.messageId === id
      )
      const localData = (localContents as Message[]).find(
        (l) => l.messageId === id
      )
      if (!remoteData && !localData) continue

      const remoteUpdated = remoteData?.updatedAt || ''
      const localUpdated = localData?.updatedAt || ''
      const latest = remoteUpdated >= localUpdated ? 'remote' : 'local'

      // @ts-ignore - one or other is always defined
      allMessages.push(latest === 'remote' ? remoteData : localData)
    }

    // Step 3: Remove expired messages. Expiry rules:
    // sent to all: delete 30 days after sent.
    // sent to a person: delete 30 days after sent.
    // sent to a walk: 7 days after walk completes.
    const allMessagesNotExpired: Message[] = []
    for (const m of allMessages) {
      if (m.status !== 'sent') {
        allMessagesNotExpired.push(m)
        continue
      }

      let threshold = constants.MESSAGES_DELETE_DAYS
      switch (m.to) {
        case 'walk':
          // Is completed and has it been 7 days?
          const walksDexie = await db.walks.toArray()
          const walk = walksDexie?.find((w) => w.walkId === m.toId)
          const isEnded =
            walk?.status === 'ended' ||
            walk?.status === 'cancelled' ||
            walk?.status === 'deleted'
          threshold = isEnded ? constants.MESSAGES_WALKS_DELETE_DAYS : 999
          break
      }

      const diff = differenceInDays(new Date(), new Date(m.sentAt))
      if (!isNaN(diff) && diff >= threshold) {
        deleteMessage(m.messageId)
      } else {
        allMessagesNotExpired.push(m)
      }
    }

    // Replace the table
    await replaceMessagesTable(allMessagesNotExpired)
  }

  // After merge
  const hasError = await sanityCheckSyncResult(
    'afterMerge',
    table,
    undefined,
    remoteId
  )
  if (hasError) return

  // In many cases (ie no local changes) the result should be the same as the remote file.
  // In that case we don't need to push
  // Get the local data
  const updatedLocalContents: User[] | Walk[] | Plb[] | Message[] | null =
    table === 'users'
      ? ((await db.users.toArray()) as User[])
      : table === 'walks'
      ? ((await db.walks.toArray()) as Walk[])
      : table === 'plbs'
      ? ((await db.plbs.toArray()) as Plb[])
      : table === 'messages'
      ? ((await db.messages.toArray()) as Message[])
      : null
  if (isEqual(updatedLocalContents, remoteContents)) {
    // log(`Updated local ${table} same as remote, so not pushing (${remoteId})`)
    // Ensure the remote id is correct
    await updateSingleSyncProperty([
      { key: table + 'RemoteId', value: remoteId },
      { key: table + 'LocalUpdates', value: false },
      { key: 'message', value: '' },
    ])

    return
  }

  /*
  // Found differences - what are they?
  const diffs = findLocalDifferences(
    table,
    updatedLocalContents,
    remoteContents
  )
  log(`Pushing updated local ${table} to remote`)
   */

  // Delete the remote file and push the result up (note: pushing will delete any non-existent "old" file too)
  await deleteS3File(table, remoteId)
  await pushToRemote(table, true)

  // After push
  await sanityCheckSyncResult('afterPush', table)
}

// Does the sync result look ok? Return a bigError or empty string if ok
const sanityCheckSyncResult = async (
  which: 'afterMerge' | 'beforePush' | 'afterPush',
  table: TableName,
  registerForWalkId = '',
  updatedRemoteId: string = ''
): Promise<string> => {
  const appStatus = await db.appStatus.get('Tripsheets')
  if (!appStatus) return ''
  // For now: no sanity check for messages or plbs
  if (table === 'plbs') return ''
  if (table === 'messages') return ''

  // Get the local data
  const localContents = await getLocalData(table)
  if (!localContents.length) {
    const msg = [WARNING]
    msg.push(`It looks like the sync can't start`)
    msg.push(`Please retry by tapping the Sync button at the top`)
    await updateSingleSyncProperty([
      { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
      { key: 'status', value: 'bigError' },
    ])
    return ''
  }

  // Get the remote data
  const remoteId = updatedRemoteId || appStatus.sync[`${table}RemoteId`]
  const remoteContents = await getS3FileContents(table, remoteId)

  if (which === 'afterMerge') {
    // Check if the merge result (now in dexie) is similar to the original that was remote
    const lengthDiff = getLengthDiff(localContents, remoteContents)
    if (lengthDiff < -2) {
      const analyticsMessage = `ESANAM ${table.slice(0, 1)} ${lengthDiff} ${
        localContents.length
      }/${remoteContents.length}`
      log(analyticsMessage, true, { userId: appStatus.userId })
      const msg = [ERROR, 'Refusing to Sync']
      msg.push(`Too large difference in the number of ${table}`)
      msg.push(
        `Tell Support: ${table}: ${which}: ${localContents.length}/${remoteContents.length}`
      )
      await updateSingleSyncProperty([
        { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
        { key: 'status', value: 'bigError' },
      ])
      return 'hasError'
    }
  } else if (which === 'beforePush') {
    // Check if the local file is similar to the remote file about to be overwritten
    if (remoteContents === ERROR_GETTING_CONTENT) {
      const analyticsMessage = `ESANBP ${table.slice(0, 1)} REMMIS`
      log(analyticsMessage, true, { userId: appStatus.userId })
      const msg = [
        WARNING,
        'Couldnt find the remote file for sanity check',
        'So will push local data',
        `Tell Support: ${table}: ${which}: remote missing?`,
      ]
      await updateSingleSyncProperty([
        { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
        { key: 'status', value: 'bigError' },
      ])
      return ''
    }
    const lengthDiff = Math.abs(getLengthDiff(localContents, remoteContents))
    if (lengthDiff > 2) {
      const analyticsMessage = `ESANBP ${table.slice(0, 1)} ${lengthDiff} ${
        localContents.length
      }/${remoteContents.length}`
      log(analyticsMessage, true, { userId: appStatus.userId })
      const msg = [ERROR, 'Refusing to Sync']
      msg.push(`Too large difference in the number of ${table}`)
      msg.push(
        `Tell Support: ${table}: ${which}: ${localContents.length}/${remoteContents.length}`
      )
      await updateSingleSyncProperty([
        { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
        { key: 'status', value: 'bigError' },
      ])
      return 'hasError'
    }

    // Check if I am correctly registered for the walk in the local data (remote is not updated yet)
    if (registerForWalkId && table === 'walks') {
      const theWalk = (localContents as Walk[]).find(
        (w) => w.walkId === registerForWalkId
      )
      const iAmWalking = theWalk?.walkers.includes(appStatus.userId) || false

      if (!iAmWalking) {
        const analyticsMessage = `WSANBP ${table.slice(0, 1)} REGFAIL`
        log(analyticsMessage, true, {
          userId: appStatus.userId,
          walkId: registerForWalkId,
        })
        const msg = [WARNING]
        msg.push(`It looks like the registration failed`)
        msg.push(`Please retry by tapping the Sync button at the top`)
        await updateSingleSyncProperty([
          { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
          { key: 'status', value: 'bigError' },
          { key: 'options', value: { action: '' } },
        ])
      }
    }
  } else if (which === 'afterPush') {
    // Check if dexie is similar to the remote file that has just been written
    const lengthDiff = Math.abs(getLengthDiff(localContents, remoteContents))
    if (lengthDiff > 2) {
      // Retry the remote contents - that seems to randomly fail :-(
      const remoteContentsAgain = await getS3FileContents(table, remoteId)
      const lengthDiffAgain = Math.abs(
        getLengthDiff(localContents, remoteContentsAgain)
      )
      if (lengthDiffAgain > 2) {
        const analyticsMessage = `ESANAPA ${table.slice(
          0,
          1
        )} ${lengthDiffAgain} ${localContents.length}/${
          remoteContentsAgain.length
        }`
        log(analyticsMessage, true, { userId: appStatus.userId })
        const msg = [ERROR, 'Sync went wrong']
        msg.push('Likely data has disappeared')
        msg.push(`Too large difference in the number of ${table}`)
        msg.push(
          `Tell Support ASAP: ${table}: ${which}: ${localContents.length}/${remoteContentsAgain.length}`
        )
        await updateSingleSyncProperty([
          { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
          { key: 'status', value: 'bigError' },
        ])
        return 'hasError'
      } else {
        // log msg max 37 chars
        log(`Retry:${table}:${which}:${lengthDiff}`, true)
      }
    }

    // Check if I am correctly registered for the walk in the remote data
    if (registerForWalkId && table === 'walks') {
      const theWalk = (remoteContents as Walk[]).find(
        (w) => w.walkId === registerForWalkId
      )
      const iAmWalking = theWalk?.walkers.includes(appStatus.userId) || false
      log(
        `Checked if registered (remote) for the walk: ${
          iAmWalking ? 'OK' : 'not registered'
        }`,
        true,
        {
          walkId: registerForWalkId,
          userId: appStatus.userId,
        }
      )
      if (!iAmWalking) {
        const analyticsMessage = `ESANAP ${table.slice(0, 1)} REGFAIL`
        log(analyticsMessage, true, {
          userId: appStatus.userId,
          walkId: registerForWalkId,
        })
        const msg = [ERROR]
        msg.push(`It looks like the registration failed`)
        msg.push(`Please try to register again`)
        await updateSingleSyncProperty([
          { key: 'message', value: msg.join(MESSAGE_SEPARATOR) },
          { key: 'status', value: 'bigError' },
        ])
      }
      await updateSingleSyncProperty([
        { key: 'options', value: { action: '' } },
      ])
    }
  }

  return ''
}

// Try a few times if unlocked. Make our lockfile
// Return '' to indicate locked-by-me or the id of the locker
const syncLock = async (
  action: 'make' | 'remove' = 'make'
): Promise<string> => {
  const currentAppStatus = await db.appStatus.get('Tripsheets')
  const userId = currentAppStatus?.userId
  if (!userId) return 'NO-CURRENT-USER'

  if (action === 'remove') {
    await deleteS3File('sync-lock', userId)
    // const remoteIds = await getS3Filenames('sync-lock')
    // console.log('directly after removing lock, result lock ids', remoteIds)
    // setTimeout(async () => {
    //   const remoteIds = await getS3Filenames('sync-lock')
    // console.log('600 ms after removing lock, result lock ids', remoteIds)
    // }, 600)
    return ''
  }

  if (!isOnline()) {
    return 'NO INTERNET'
  }

  // Check and make a lock file
  const lockedBy = await new Promise<string>(async (resolve) => {
    let retryCount = 0
    await updateSingleSyncProperty([
      { key: 'message', value: `Sync attempt ${++retryCount}` },
    ])
    let remoteIds = await getS3Filenames('sync-lock')
    let returnText = '__initial__'
    if (
      remoteIds.length === 2 &&
      collapseText(remoteIds[1]) === 'failedtofetch'
    ) {
      // No connection
      returnText = !isOnline() ? 'NO INTERNET' : 'CONNECTION ISSUE'
      resolve(returnText)
      return
    }

    if (!remoteIds.length) {
      // Sync is not locked, so lock by me
      const result = await createS3JsonFile(
        'sync-lock',
        userId,
        new Date().toISOString()
      )
      resolve(result.isSuccess ? '' : result.message)
      return
    }

    if (remoteIds.length > 1) {
      // Sync is locked by multiple people. Is one of those mine?
      if (remoteIds.includes(userId)) {
        await deleteS3File('sync-lock', userId) // Delete my sync
        remoteIds = await getS3Filenames('sync-lock') // Refresh the sync ids
      } else {
        log(`WMULSYN ${remoteIds.join(',')}`, true, { userId })
        resolve('WAIT 10 MINUTES')
        return
      }
    }

    // Sync is locked, so try to unlock
    await updateSingleSyncProperty([
      { key: 'message', value: `Sync attempt ${++retryCount}` },
    ])
    const stillLockedBy = await tryUnlockSync(remoteIds[0], userId)
    if (!stillLockedBy) {
      // Make a lock for me
      createS3JsonFile('sync-lock', userId, new Date().toISOString())
      resolve('')
      return
    }
    resolve(stillLockedBy)
    return

    /* Don't do this, the timeout fires and makes a lock after the action has finished
    // try to unlock again in 2 secs
    setTimeout(async () => {
      await updateSingleSyncProperty([
        { key: 'message', value: `Sync attempt ${++retryCount}` },
      ])
      const stillLockedBy = await tryUnlockSync(remoteIds[0], userId)
      if (!stillLockedBy) {
        // Lock by me
        await updateSingleSyncProperty([{ key: 'message', value: `` }])
        createS3JsonFile('sync-lock', userId, new Date().toISOString())
        resolve('')
      }
      await updateSingleSyncProperty([{ key: 'message', value: `` }])
      resolve(stillLockedBy)
    }, 2000)
     */
  })

  return lockedBy
}

const tryUnlockSync = async (
  lockedBy: string,
  userId: string
): Promise<string> => {
  // Am I locking and >1 min => unlock
  // Others locking and >10 min => unlock
  const timeoutSec = lockedBy === userId ? 10 : 600
  const lockTime = (await getS3FileContents('sync-lock', lockedBy)) as string
  const timeDiffSec = differenceInSeconds(new Date(), new Date(lockTime))
  if (timeDiffSec < timeoutSec) return lockedBy

  // Timeout passed so takeover the lock
  log(`Taking over previous lock by ${lockedBy === userId ? 'me' : lockedBy}`)
  deleteS3File('sync-lock', lockedBy)
  return ''
}

export const fixSyncProblems = async () => {
  let delCount = 0
  // Only keep the latest sync files
  for (const table of ['users', 'walks'] as TableName[]) {
    const remoteIds = await getS3Filenames(table)
    remoteIds.map((id, index) => {
      if (index === 0) return
      deleteS3File(table, id)
      delCount++
    })
  }

  // Remove all sync locks
  const table = 'sync-lock'
  const remoteSyncLockIds = await getS3Filenames(table)
  remoteSyncLockIds.map((id, index) => {
    deleteS3File(table, id)
    delCount++
  })

  await syncWithRemote()

  log(`Sync unlocked and fixed. Deleted ${delCount} files`)
}

const checkForMultipleSyncs = async (table: TableName) => {
  // Check if there are >1 syncLock files
  const remoteIds = await getS3Filenames('sync-lock')
  if (remoteIds.length > 1) {
    log(
      `ESYNMUL ${remoteIds.length} ${remoteIds[0]} ${
        remoteIds[1]
      } ${table.slice(0, 1)}`,
      true
    )
    return remoteIds
  }
  return null
}
