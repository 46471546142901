import { Fragment, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  Divider,
  GridItem,
  IconButton,
  Select,
  SimpleGrid,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import './walks.css'
import { palette, paletteDark } from '../config'
import { CheckIcon, CloseIcon } from '@chakra-ui/icons'
import { User } from '../User/User'
import { useLiveQuery } from 'dexie-react-hooks'
import { db, WalkerChangeType } from '../store/db'
import { syncWithRemote } from '../common/syncWithRemote'
import { updateWalk } from './walks.model'
import { getUser } from '../User/user.model'

type Props = {
  walkId: string
  onClick: () => void
}
export const AddNewWalker = (props: Props) => {
  const walksDexie = useLiveQuery(() => db.walks.toArray())
  const heavyBgColor = useColorModeValue(
    palette.heavyBackground,
    paletteDark.heavyBackground
  )
  const [addClass, setAddClass] = useState('')

  // Effect to slide in the panel
  useEffect(() => {
    setAddClass('addNewWalker')
  }, [])

  const addWalker = async (id: string) => {
    if (!id || !walksDexie) return
    props.onClick()

    // Ensure we're upto date in case other walkers have processed their changes already
    await syncWithRemote()

    const walk = walksDexie.find((w) => w.walkId === props.walkId)
    if (!walk) return

    const user = await getUser(id)

    const walkersUpdated = [...walk.walkers, id]
    const changesUpdated = {
      ...walk.walkerChangesByLeader,
      [id]: WalkerChangeType.Added,
    }
    await updateWalk(
      {
        ...walk,
        walkers: walkersUpdated,
        walkerChangesByLeader: changesUpdated,
      },
      `added new walker ${user?.fullName || 'Error/Unknown'}`
    )

    syncWithRemote()
  }

  return (
    <>
      <Box className={`makeWalkContainer ${addClass}`} bg={heavyBgColor}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Text as={'b'}>Add a new walker</Text>

          {/* Close icon */}
          <IconButton
            colorScheme={palette.action}
            aria-label="Close add new walker"
            icon={<CloseIcon />}
            size={'xs'}
            onClick={props.onClick}
          />
        </Box>

        <User leaderAddsNewWalker onClick={(id: string) => addWalker(id)} />
      </Box>
    </>
  )
}
