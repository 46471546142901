import { formatISO } from 'date-fns'
import { db, User, Walk } from '../store/db'
import {
  syncWithRemote,
  updateSingleSyncProperty,
} from '../common/syncWithRemote'
import { getLocation, log } from '../common/utils'
import nid from 'nid'

export const deleteWalk = async (id?: string): Promise<void> => {
  if (!id) return
  await db.walks.update(id, {
    status: 'deleted',
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'walksLocalUpdates', value: true }])
}

export const hardDeleteWalkFromDb = async (id?: string): Promise<void> => {
  if (!id) return
  await db.walks.delete(id)
  await updateSingleSyncProperty([{ key: 'walksLocalUpdates', value: true }])
}

export const addWalk = async (walk: Walk): Promise<void> => {
  db.walks.add(walk)
  await updateSingleSyncProperty([{ key: 'walksLocalUpdates', value: true }])
}

export const getWalk = async (walkId?: string): Promise<Walk | undefined> => {
  return walkId ? await db.walks.get(walkId) : undefined
}

export const updateWalk = async (
  walk: Walk,
  chronologyAction?: string
): Promise<number> => {
  let newChronology = [...(walk.chronology || [])]
  if (chronologyAction) {
    const currentAppStatus = await db.appStatus.get('Tripsheets')
    const currentUser =
      currentAppStatus && (await db.users.get(currentAppStatus.userId))
    newChronology.push({
      chronologyId: nid(),
      timestamp: formatISO(new Date()),
      action: chronologyAction,
      byUserName: currentUser?.fullName || 'Error/Unknown',
    })
  }

  const result = await db.walks.update(walk.walkId, {
    ...walk,
    chronology: newChronology,
    updatedAt: formatISO(new Date()),
  })
  await updateSingleSyncProperty([{ key: 'walksLocalUpdates', value: true }])
  return result
}

export const registerFor = async (
  walkId: string,
  userId?: string,
  action?: 'cancel'
): Promise<number | null> => {
  if (!walkId || !userId) return 0
  const walk = await db.walks.get(walkId)
  if (!walk) return null

  let newWalkers: string[] = walk?.walkers || []
  if (!action) {
    newWalkers.push(userId)
  } else {
    newWalkers = newWalkers.filter((w: string) => w !== userId)
  }
  const uniqueWalkers = [...new Set(newWalkers)]
  const updatedWalk = {
    ...walk,
    walkers: uniqueWalkers,
  }

  const result = await updateWalk(
    updatedWalk,
    `${action === 'cancel' ? 'cancelled' : 'registered'} to walk`
  )
  return result ? uniqueWalkers.length : null
}

export const attachmentFor = async (
  walkId: string,
  fileId?: string,
  action?: 'remove'
): Promise<number> => {
  if (!walkId || !fileId) return 0

  try {
    const walk = await db.walks.get(walkId)
    let newFiles = walk?.fileIds || []
    if (!action) {
      newFiles.push(fileId)
    } else {
      newFiles = newFiles.filter((w) => w !== fileId)
    }
    const uniqueFileIds = [...new Set(newFiles)]
    const result = await db.walks.update(walkId, {
      fileIds: uniqueFileIds,
      updatedAt: formatISO(new Date()),
    })
    await updateSingleSyncProperty([{ key: 'walksLocalUpdates', value: true }])

    return result ? uniqueFileIds.length : 0
  } catch (e) {
    console.log('error attach', e)
    return 0
  }
}

export const startWalk = async (walkId: string) => {
  // Set status to started, record time and gps
  const walk = await db.walks.get(walkId)
  if (!walk) return
  await updateWalk(
    {
      ...walk,
      status: 'started',
      startedAt: formatISO(new Date()),
    },
    'started the walk'
  )
  await setLocationFor(walkId, 'startGps')
  await syncWithRemote()
  await log('Started:', true, { walkId })
}

export const endWalk = async (walkId: string) => {
  const walk = await db.walks.get(walkId)
  if (!walk) return
  await updateWalk(
    {
      ...walk,
      status: 'ended',
      endedAt: formatISO(new Date()),
    },
    'ended the walk'
  )
  await setLocationFor(walkId, 'endGps')
  await syncWithRemote()
  await log('Ended:', true, { walkId })
}

export const addLeaderFor = async (
  walkId: string,
  userId: string,
  action?: 'remove'
) => {
  if (!walkId || !userId) return

  const walk = await db.walks.get(walkId)
  if (!walk) return
  let currentLeaders = walk?.leaderId || []
  if (typeof currentLeaders === 'string') currentLeaders = [currentLeaders]

  let newLeaders = currentLeaders || []
  if (!action) {
    newLeaders.push(userId)
  } else {
    newLeaders = newLeaders.filter((leaderId) => leaderId !== userId)
  }
  const uniqueLeaders = [...new Set(newLeaders)]

  await updateWalk(
    {
      ...walk,
      leaderId: uniqueLeaders,
    },
    `${action === 'remove' ? 'is not leading' : 'became a leader'}`
  )
  await syncWithRemote()
}

const setLocationFor = async (walkId: string, key: string): Promise<void> => {
  if (!navigator.geolocation) return

  db.walks.update(walkId, { [key]: 'Getting location...' })

  const pos = await getLocation()
  if (pos.slice(0, 3) === 'LON') {
    db.walks.update(walkId, { [key]: pos, updatedAt: formatISO(new Date()) })
  } else {
    db.walks.update(walkId, { [key]: '', updatedAt: formatISO(new Date()) })
  }
}

export const replaceWalksTable = async (newWalks: Walk[]): Promise<void> => {
  try {
    const allWalks = await db.walks.toArray()
    const allWalkIds = allWalks.map((u) => u.walkId)
    await db.walks.bulkDelete(allWalkIds)
    await db.walks.bulkPut(newWalks)
  } catch (e: any) {
    await log(`Error while replacing walks table: ${e.message}`)
  }
}
